<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
import Cache from "@/utils/cache";

export default {
  onLaunch: function (params) {
    //  清楚缓存
    // #ifndef H5
    this.$log.debug("清楚缓存开始")
    Cache.clearPrefix(process.env.VUE_APP_REQUEST_CACHE_PREFIX);
    this.$log.debug("清楚缓存结束")
    // #endif

    let that = this;
    // #ifdef H5
    uni.getSystemInfo({
      success: function (res) {
        // 首页没有title获取的整个页面的高度，里面的页面有原生标题要减掉就是视口的高度
        // 状态栏是动态的可以拿到 标题栏是固定写死的是44px
        let height = res.windowHeight - res.statusBarHeight - 44
        // #ifdef H5
        that.globalData.windowHeight = res.windowHeight + 'px'
        // #endif
      }
    });
    // #endif
    // 获取导航高度；
    uni.getSystemInfo({
      success: function (res) {
        that.globalData.navHeight = res.statusBarHeight * (750 / res.windowWidth) + 91;
      }
    });
    // #ifdef MP
    let menuButtonInfo = uni.getMenuButtonBoundingClientRect();
    that.globalData.navH = menuButtonInfo.top * 2 + menuButtonInfo.height / 2;
    // #endif
    // #ifndef H5
    // if ("development" === process.env.NODE_ENV) {
    //   // 这里的代码会在应用启动或从后台进入前台时执行
    //   // 使用uni.navigateTo跳转到指定页面
    //   uni.navigateTo({
    //     url: '/frequent/bill/details?id=562416050196549' // 替换为你的目标页面路径
    //   });
    // }
    // #endif
  },
  onShow: function () {
    // #ifdef H5
    uni.getSystemInfo({
      success(e) {
        /* 窗口宽度大于420px且不在PC页面且不在移动设备时跳转至 PC.html 页面 */
        if (e.windowWidth > 420 && !window.top.isPC && !/iOS|Android/i.test(e.system)) {
          // window.location.pathname = 'https://java.crmeb.net/';
          /* 若你的项目未设置根目录（默认为 / 时），则使用下方代码 */
          window.location.pathname = '/static/html/pc.html';
        }
      }
    })
    // #endif
  },
  onHide: function () {
    console.log('App Hide')
  }
}
</script>

<style lang="scss">
/* 注意要写在第一行，同时给style标签加入lang="scss"属性 */
@import "uview-ui/index.scss";
@import '@/static/css/base.scss';

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'AlibabaPuHuiTiH';  //这个名称可以自己随便定义
  src: url('~@/static/font/dSpPPnpmlofb.woff2') format('woff2');
  font-display: swap;
}

/*使用指定字体*/
* {
  font-family: uicon-iconfont, AlibabaPuHuiTiH;
}
</style>