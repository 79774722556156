import {get, post} from "@/utils/request.js";

/**
 * 首页户卡片
 */
export function homeSuiteCard(data, isLoading) {
    return post("/house/housing/suite/card", data , isLoading);
}

/**
 * 首页户卡片
 */
export function homeSuiteList() {
    return get("/house/housing/uSuite", undefined , true);
}

/**
 * 加入户详情
 */
export function joinSuiteDetail(id) {
    return get(`/house/housing/uSuite/${id}`);
}

/**
 * 户详情
 */
export function suiteDetail(id) {
    return get(`/house/housing//uSuite/details/${id}`);
}

/**
 * 区域
 */
export function regionsOptions(data) {
    return post("/house/housing/building/regions", data, false);
}

/**
 * 查询加入数据
 */
export function showInvite(inviteCode) {
    return get(`/house/housing/suite/findInvite/${inviteCode}`);
}

/**
 * 加入确认
 */
export function joinConfirm(data) {
    return post("/house/housing/suit/agreeInvite", data, true);
}

/**
 * 续租
 */
export function renewalOfContract(code) {
    return post(`/house/housing/uJoin/renewal/${code}`, undefined, true)
}

/**
 * 账单列表
 */
export function billPageList(data, isLoading) {
    return post("/house/housing/bill/pageList", data, isLoading);
}

/**
 * 账单项列表
 */
export function billItemList(billId) {
    return get(`/house/housing/bill/item/${billId}`, undefined, true)
}

/**
 * 账单详情
 */
export function billDetails(id) {
    return get(`/house/housing/uBill/${id}`, undefined, true)
}

/**
 * 账单详情
 */
export function billShareDetails(id) {
    return get(`/house/housing/uBill/share/${id}`, undefined, true)
}

/**
 * 加入列表
 */
export function joinList() {
    return get('/house/housing/uJoin', undefined, true)
}

/**
 * 加入列表
 */
export function joinDetails(id) {
    return get(`/house/housing/uJoin/${id}`, undefined, true)
}

/**
 * 加入列表
 */
export function suiteList() {
    return get('/house/housing/uSuite', undefined, true)
}

/**
 * 统计
 */
export function analysis() {
    return get('/house/housing/uJoin/analysis');
}

/**
 * 查询配置
 */
export function findPayQrcode(buildingId) {
    return get(`/house/housing/uConfig/${buildingId}`, undefined, true, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}
/************************session***********************/

/**
 * 验证码发送
 * @param object phone
 */
export function registerVerify(phone) {
    return post('/session/auth/sendSms', {phone: phone})
}


/**
 * 手机号+验证码登录接口
 */
export function loginMobile(data) {
    return post('/session/auth/smsLogin', data, true)
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
    return get('/session/auth/getCurrentUser');
}

/**
 * 设置当前用户数据
 */
export function updateCurrentUser(data) {
    data.phone = undefined
    return post('/session/auth/updateCurrentUser', data);
}

/**
 * 创建意见/建议
 */
export function createOpinion() {
    return post('/session/auth/createOpinion');
}
/**********************./session***********************/